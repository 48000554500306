.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
}

* {
  color: rgba(255, 255, 255, 1);
  font-family: 'VT323';

}
.hero_container{
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_container {
  position: absolute;
  top: 47px;
  left: 41px;
}

.collection_text {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  margin-top: 120px;
  text-align: center;
  letter-spacing: 0.03em;
}

.contact_form {
  display: flex;
  flex-direction: column;
  max-width: 886px;
  width: 100%;
  margin-top: 110px;

}

.form_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

}

.name {
  font-weight: 400;
  font-size: 40px;
  width: 48%;
  background: #D9D9D9;
  text-align: center;
  padding: 15px;
  color: rgba(173, 173, 173, 1);
  outline: none;

}
.captcha{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.err-recaptcha{
  color: red;
  margin-top: 50px;
}
@media screen and (max-width: 1440px) {
  .collect_img{
    width: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .collect_img{
    width: 150px;
  }
}
@media screen and (max-width: 768px) {

  .name {
    width: 100% !important;
   
  }
  .subject {
    width: 100% !important;
    margin-top: 5px;
  }
  .form_container{
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 425px) {
  .email, .name, .subject, .message{
    font-size: 25px !important;
  }

  .knock_img{
    top: 132px;
    right: -70px !important; 
  }
  .collection_text {
    margin-top: 50px;
    font-size: 36px;
  }
  .description{
    font-size: 30px !important;
  }
  .collection_view{
    margin: 60px 0 !important;
  }
  .collect_img{
    width: 100px;
  }
  .hero_img {
    width: 250px;
  }
  .hero_container{
    height: 600px;
  }
  .logo_container{
    left: 10px;
    top: 10px;
  }
  .logo_img{
    width: 80px;
  }
}
@media screen and (max-width: 375px) {
.view_img{
  width: 150px;
}
}
.collection_items{
  margin: 120px 0;
  margin-left: -100px;
  position: relative;
  display: grid;
  overflow: hidden;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  transform: rotate(-6.15deg);
  width: calc(100vw + 50%);
}
.collection_items_first{
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
   
}
.collection_items_second{
  overflow: hidden;
  display: flex;
}
.collect_img{
  margin: 10px;
}
.subject {
  font-weight: 400;
  font-size: 40px;
  width: 48%;
  background: #D9D9D9;
  color: rgba(173, 173, 173, 1);
  text-align: center;
  padding: 15px;
  outline: none;
}

.email {
  width: 100%;
  font-weight: 400;
  font-size: 40px;
  background: #D9D9D9;
  text-align: center;
  padding: 15px;
  color: rgba(173, 173, 173, 1);
  outline: none;
}

.message {
  width: 100%;
  height: 280px;
  font-weight: 400;
  font-size: 40px;
  background: #D9D9D9;
  padding: 15px;
  color: rgba(173, 173, 173, 1);
  outline: none;
}

.submit {
  width: 259px;
  height: 51px;
  background: #FF5F1B;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 52px;
  margin-bottom: 40px;
  cursor: pointer;
}
.description{
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.03em;
  text-align: center;
  width: 200px;
  margin-top: 20px;
}
.collection_view{
  display: flex;
  justify-content: center;
  margin: 120px auto;
  flex-wrap: wrap;
  gap: 40px;
}
.collection_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
.form{
  display: flex;
  width: 100%;
  justify-content: center;

}
.collection_text_ref{
  cursor: pointer;
  text-decoration: underline;
}
.hero_logo_view{
  position: relative;
}
.knock_img{
  position: absolute;
  top: 132px;
  right: -120px;
}